import { invoke } from '@withease/factories';
import { createEvent, createStore, sample } from 'effector';

import { CreateUrlDto, CreateUrlResult } from '@/shared/api/gen';
import { createShortUrlMutation } from '@/shared/api/url';
import { env } from '@/shared/config/env.mjs';
import { createClipboard } from '@/shared/lib/effector/copy';

export const $createUrlResponse = createStore<CreateUrlResult | null>(null);

export const $shortUrl = $createUrlResponse.map((shortenedUrl) =>
  shortenedUrl ? `${env.NEXT_PUBLIC_APP_URL}/${shortenedUrl?.urlId}` : null,
);

export const urlFormSubmitted = createEvent<CreateUrlDto>();

sample({
  clock: urlFormSubmitted,
  target: createShortUrlMutation.start,
});

sample({
  clock: createShortUrlMutation.started,
  fn: () => null,
  target: $createUrlResponse,
});

sample({
  clock: createShortUrlMutation.finished.success,
  fn: (request) => request.result,
  target: $createUrlResponse,
});

export const urlClipboard = invoke(createClipboard);
export const urlCopyClicked = createEvent();
export const $urlCopied = urlClipboard.$hasCopied;

sample({
  clock: urlCopyClicked,
  source: $shortUrl,
  filter: (shortUrl) => shortUrl !== null,
  target: urlClipboard.copy,
});

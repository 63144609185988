import Link from 'next/link';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUnit } from 'effector-react';
import { useForm } from 'react-hook-form';
import { RiBarChartFill } from 'react-icons/ri';

import { createUrlBody, CreateUrlDto } from '@/shared/api/gen';
import { createShortUrlMutation } from '@/shared/api/url';
import { Button } from '@/shared/components/ui/button';
import { Card } from '@/shared/components/ui/card';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shared/components/ui/form';
import { Input } from '@/shared/components/ui/input';
import { pagesPath } from '@/shared/config/$path';

import { $createUrlResponse, $shortUrl, $urlCopied, urlCopyClicked, urlFormSubmitted } from './model';

export const UrlForm = () => {
  const { shortenedUrl, urlFormSubmit, urlFormLoading, shortenedUrlResponse, copyUrl, isCopied } = useUnit({
    urlFormSubmit: urlFormSubmitted,
    urlFormLoading: createShortUrlMutation.$pending,
    shortenedUrlResponse: $createUrlResponse,
    shortenedUrl: $shortUrl,
    isCopied: $urlCopied,
    copyUrl: urlCopyClicked,
  });

  const form = useForm<CreateUrlDto>({
    resolver: zodResolver(createUrlBody),
    defaultValues: {
      url: '',
    },
  });

  return (
    <Card className="w-full p-4">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(urlFormSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="url"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Long URL</FormLabel>
                <FormControl>
                  <Input type="url" placeholder="https://example.com/?foo=long&bar=very-long" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {shortenedUrl && (
            <div className="flex items-end space-x-4">
              <FormItem className="w-full">
                <FormLabel>Urlbite URL</FormLabel>
                <FormControl>
                  <Input type="url" value={shortenedUrl} readOnly />
                </FormControl>
              </FormItem>

              <Button type="button" onClick={copyUrl}>
                {isCopied ? 'Copied!' : 'Copy'}
              </Button>

              <Button variant="outline" type="button" asChild>
                <Link target="_blank" href={pagesPath.urls._urlId(shortenedUrlResponse!.urlId).$url()}>
                  Stats
                  <RiBarChartFill className="ml-2 h-4 w-4" />
                </Link>
              </Button>
            </div>
          )}

          <Button className="w-full" type="submit" disabled={urlFormLoading}>
            Shorten
          </Button>
        </form>
      </Form>
    </Card>
  );
};

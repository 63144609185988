import { createFactory } from '@withease/factories';
import copyToClipboard from 'copy-to-clipboard';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { delay, readonly } from 'patronum';

export const createClipboard = createFactory(() => {
  const $hasCopied = createStore(false);

  const runCopy = createEvent<string>();

  const copyFx = createEffect(copyToClipboard);

  sample({
    clock: runCopy,
    target: copyFx,
  });

  sample({
    clock: runCopy,
    fn: () => true,
    target: $hasCopied,
  });

  delay({
    source: copyFx.doneData,
    timeout: 2000,
    target: $hasCopied.reinit,
  });

  return {
    copy: runCopy,
    $hasCopied: readonly($hasCopied),
  };
});
